






























































































































































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    CenteredColumnLayout,
    PageTitle,
    ConfigField,
    NotAvailableOptionsOverlay
  },
  data() {
    return {
      getTariffTranslationKey,
      MediaEditorMode,
      StepUnit
    }
  }
})
export default class Extra extends Mixins(UseFields, InputSetups, TariffsTagsHelper) {
  get from(): string {
    return this.$store.state.chatState.chat.config.close_chat_interval.from
  }

  isFieldDisabled ():boolean | undefined {
    if (this.getTagsByFieldKey('close_chat') !== null) {
      let tag: Array<any> | null = this.getTagsByFieldKey('close_chat')
      if (tag !== null && tag[0])
        return tag[0].color !== ''
      return true
    }
  }

  set from(fromValue: string) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.close_chat_interval.from = fromValue
      }
    })
  }

  get to(): string {
    return this.$store.state.chatState.chat.config.close_chat_interval.to
  }

  set to(toValue: string) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.close_chat_interval.to = toValue
      }
    })
  }

  closeChatBanTypeOptions() {
    return new Promise(resolve => resolve([
      { label: this.$t('close_chat_type_all'), value: 'All' },
      { label: this.$t('close_chat_type_media'), value: 'Media' },
    ]))
  }

  nsfwBanTypeOptions() {
    return new Promise(resolve => resolve([
      { label: this.$t('nsfw_ban_type_none'), value: 'None' },
      { label: this.$t('nsfw_ban_type_ban'), value: 'Ban' },
      { label: this.$t('nsfw_ban_type_kick'), value: 'Kick' },
      { label: this.$t('nsfw_ban_type_mute'), value: 'Mute' },
      { label: this.$t('nsfw_ban_type_mute_media'), value: 'MuteMedia' },
    ]))
  }
}
