var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"moderation-extra"},[_c('centered-column-layout',[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',[_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'remove_system_pin_message',
            'hasAnchor': true,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'remove_system_voice_chat_messages' ,
            'hasAnchor': true,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'remove_edited',
            'hasAnchor': true,
          }
        }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.remove_edited)?_c('nested-content',[_c('time-unit-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'remove_edited_timeout',
                'hasAnchor': true,
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'remove_edited_msg',
                'hasAnchor': true,
              }
            }}})],1):_vm._e()],1),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'automatic_text_correction',
            'hasAnchor': true,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'filter_albums_items_count',
            'hasAnchor': true,
          }
        }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.filter_albums_items_count)?_c('nested-content',[_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'filter_albums_items_count_limit',
                'max': 20,
                'hasAnchor': true,
              }
            }}})],1):_vm._e()],1),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'deferred_remove_ban_bot_messages' ,
            'hasAnchor': true,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'facecontrol_username_remove_bots' ,
            'hasAnchor': true,
          }
        }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.facecontrol_username_remove_bots)?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'facecontrol_username_ban_bot_add_user',
                'hasAnchor': true,
              }
            }}})],1):_vm._e()],1)],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('filters_extra_settings_title')}},[_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'ban_time_1',
            'step': _vm.StepUnit.Minute,
            'hasAnchor': true,
          }
        }}}),_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'ban_time_2',
            'step': _vm.StepUnit.Minute,
            'hasAnchor': true,
          }
        }}}),_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'ban_time_3',
            'step': _vm.StepUnit.Minute,
            'hasAnchor': true,
          }
        }}}),_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'ban_time_4',
            'step': _vm.StepUnit.Minute,
            'hasAnchor': true,
          }
        }}}),_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'ban_time_5',
            'step': _vm.StepUnit.Minute,
            'hasAnchor': true,
          }
        }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_filter_invalid_content_title')}},[_c('a-alert',{staticClass:"mx-2",attrs:{"description":_vm.$t('filter_invalid_content_alert_text')}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'nsfw_ban_type',
            'options': _vm.nsfwBanTypeOptions,
            'multiple': false,
            'clearable': false,
            'tariffTags': _vm.getTagsByFieldKey('delete_porno'),
            'hasAnchor': true,
          }
        }}})],1),_c('a-card',{staticClass:"auto-attack-mode_wrapper mt-5",attrs:{"title":_vm.$t('field_auto_attack_mode_title')}},[_c('number-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'auto_attack_mode_count',
            'tariffTags': _vm.getTagsByFieldKey('auto_attack_mode'),
            'hasAnchor': true,
          }
        }}}),_c('time-unit-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'auto_attack_mode_interval',
            'tariffTags': _vm.getTagsByFieldKey('auto_attack_mode'),
            'hasAnchor': true,
          }
        }}})],1),_c('a-card',{staticClass:"close-chat-type_wrapper mt-5",attrs:{"title":_vm.$t('field_close_chat_header_title')}},[_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'close_chat_type',
            'options': _vm.closeChatBanTypeOptions,
            'multiple': false,
            'clearable': false,
            'tariffTags': _vm.getTagsByFieldKey('close_chat'),
            'hasAnchor': true,
          }
        }}}),_c('div',{staticClass:"close_chat-time-wrapper flex justify-between items-end"},[_c('time-picker-input',{staticClass:"w-full",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': this,
              'key': 'from',
              'prefix': 'moderation_extra_',
              'clearable': false,
              'time24hr': true,
              'disabled': _vm.isFieldDisabled(),
              'hasAnchor': true,
            }
          }}}),_c('time-picker-input',{staticClass:"w-full",attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': this,
              'key': 'to',
              'prefix': 'moderation_extra_',
              'clearable': false,
              'time24hr': true,
              'disabled': _vm.isFieldDisabled(),
              'hasAnchor': true,
            }
          }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.from || _vm.to)?_c('a-button',{staticClass:"ml-1 flex-1 sm:flex-none self-end",attrs:{"type":"danger","icon":"delete","disabled":!!_vm.getTagsByFieldKey('close_chat')},on:{"click":function (){ this$1.from = '00:00:00'; this$1.to = '00:00:00' }}}):_vm._e()],1)],1),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'open_chat_message',
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'tariffTags': _vm.getTagsByFieldKey('close_chat'),
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
          },
        }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'close_chat_message',
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'tariffTags': _vm.getTagsByFieldKey('close_chat'),
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
          },
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }